import React, { useState, useEffect, useContext } from 'react';
import { Container } from '../../components/Container';
import { useLocation, useNavigate } from 'react-router-dom';

import { ApiContext } from '../../contexts/ApiContext';
import FetchUserAndFirebaseDocsOnAuth from '../../components/fetchFirebaseDocsOnAuth.js';

import { db } from '../../firebase';
import { collection, doc, getDoc, getDocs, updateDoc } from 'firebase/firestore';

import AffiliateDetails from './AffiliateDetails';
import AffiliateSalesTransactions from './AffiliateSalesTransactions';
import AffiliateRevenueCatSalesTransactions from './AffiliateRevenueCatSalesTransactions';
import AffiliateEvents from './AffiliateEvents';
import BillingAlertModal from '../../components/BillingAlertModal';

const AffiliateSearch = () => {
    const [tableData, setTableData] = useState([]);
    const [eventsTableData, setEventsTableData] = useState([]);
    const [affiliateCode, setAffiliateCode] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [error, setError] = useState(null);
    const [searchExecuted, setSearchExecuted] = useState(false);
    const [newUnpaidTransactions, setNewUnpaidTransactions] = useState([]);
    const {
        iapticAppName,
        apiUrl,
        subscriptionStatus,
        companyOfferCouponCodes,
        companyAffiliatePaymentPercentage,
        selectedVerificationMethod
    } = useContext(ApiContext);

    const [localCompanyOfferCouponCodes, setLocalCompanyOfferCouponCodes] = useState(
        (companyOfferCouponCodes || []).map(code => ({
            value: code.value,
            label: `${code.value} (expires: ${new Date(code.expiry).toLocaleDateString()})`,
            expiry: code.expiry
        }))
    );

    const {companyId, iapticSecretKey} = useContext(ApiContext)
    const user = FetchUserAndFirebaseDocsOnAuth();
    const location = useLocation();
    const navigate = useNavigate();

    const [affiliateEmail, setAffiliateEmail] = useState('');
    const [affiliateName, setAffiliateName] = useState('');
    const [affiliateShortCode, setAffiliateShortCode] = useState('');
    const [deepLinkUrl, setDeepLinkUrl] = useState('');
    const [affiliateOfferCode, setAffiliateOfferCode] = useState('');
    const [localAffiliateOfferCode, setLocalAffiliateOfferCode] = useState('');
    const [transactionsAffiliateHasBeenPaidFor, setTransactionsAffiliateHasBeenPaidFor] = useState([]);
    const [transactionsAffiliateHasNotBeenPaidFor, setTransactionsAffiliateHasNotBeenPaidFor] = useState([]);

    const [totalAffiliateHasBeenPaid, setTotalAffiliateHasBeenPaid] = useState(0);
    const [totalAffiliateHasNotBeenPaid, setTotalAffiliateHasNotBeenPaid] = useState(0);
    const [loading, setLoading] = useState(true);
    const [view, setView] = useState('Transactions'); 
    const [selectedCode, setSelectedCode] = useState('');

    const handleChange = (newValue, actionMeta) => {
        setSelectedCode(newValue);
        setLocalAffiliateOfferCode(newValue);
    };

    const handleCreate = (inputValue) => {
        const expiryDate = prompt('Enter expiry date (YYYY-MM-DD):');
        const newOption = {
            value: inputValue,
            label: `${inputValue} (expires: ${new Date(expiryDate).toLocaleDateString()})`,
            expiry: expiryDate
        };
        setLocalCompanyOfferCouponCodes((prevOptions) => [...prevOptions, newOption]);
        setSelectedCode(newOption);
    };


    useEffect(() => {
        if (companyOfferCouponCodes !== '' && companyOfferCouponCodes !== localCompanyOfferCouponCodes) {
          setLocalCompanyOfferCouponCodes(
            companyOfferCouponCodes.map(code => ({
                value: code.value,
                label: `${code.value} (expires: ${new Date(code.expiry).toLocaleDateString()})`,
                expiry: code.expiry
            }))
          );
        }
    }, [companyOfferCouponCodes]);

    useEffect(() => {
        if (affiliateOfferCode !== '' && affiliateOfferCode != null && affiliateOfferCode !== localAffiliateOfferCode) {
            const selectedOption = localCompanyOfferCouponCodes.find(option => option.value === affiliateOfferCode);
            setLocalAffiliateOfferCode(affiliateOfferCode);
            setSelectedCode(selectedOption || { value: affiliateOfferCode, label: affiliateOfferCode });
        }
    }, [affiliateOfferCode]);

    useEffect(() => {
        if (subscriptionStatus === false || subscriptionStatus === undefined) {
            navigate('/billing');
        }
    }, [subscriptionStatus, navigate]);

    useEffect(() => {
        if (user !== null && iapticAppName !== '' && iapticSecretKey !== '') {
            // Check for affiliate parameter in URL
            const params = new URLSearchParams(location.search);
            const affiliateParam = params.get('affiliate');
            if (affiliateParam) {
                setAffiliateCode(affiliateParam);
            }
        }
    }, [user, iapticAppName, iapticSecretKey]);

    useEffect(() => {
        if (user !== null && iapticAppName !== '' && iapticSecretKey !== '' && affiliateCode !== '') {
            searchAnAffiliate();
            setLoading(false);
        } else {
            const timeout = setTimeout(() => {
              setLoading(false);
            }, 500);
            
            return () => clearTimeout(timeout);
        }
    }, [user, iapticAppName, iapticSecretKey, affiliateCode, startDate, endDate]);

    useEffect(() => {
        if (user !== null && loading === false) {
          if (iapticAppName === '' || iapticSecretKey === '') {
            searchAnAffiliate(true);
          }
        }
    }, [user, loading]);

    useEffect(() => {
        if (view === 'Events') {
            searchAnAffiliatesEvents();
        }
    }, [view]);

    useEffect(() => {
        if (newUnpaidTransactions.length > 0) {
            const updateFirebase = async () => {
                try {
                    //     // TODO: this should be done in a use effect right?
                    const firebaseAffiliates = await fetchFirestoreAffiliates(user.uid);

                    const foundEmail = findAffiliateEmailInFirebaseFromUsername(affiliateCode, firebaseAffiliates);
                    // Update Firebase
                    const companiesRef = collection(db, 'Companies');
                    const userDocRef = doc(companiesRef, user.uid);
                    const affiliateRef = doc(collection(userDocRef, 'Affiliates'), foundEmail);
        

                    await updateDoc(affiliateRef, {
                        transactionsAffiliateHasNotBeenPaidFor: [
                            ...transactionsAffiliateHasNotBeenPaidFor,
                            ...newUnpaidTransactions,
                        ],
                    });
                } catch (error) {
                    console.error("Error updating Firebase:", error);
                }
            };
            updateFirebase();
        }
    }, [transactionsAffiliateHasNotBeenPaidFor, newUnpaidTransactions]);
    

    const handleSubmit = (e) => {
        e.preventDefault();
        updateFirebaseAffiliateOfferCode({
            affiliateEmail,
            localAffiliateOfferCode
        });
        const offerCouponCodesAsStrings = localCompanyOfferCouponCodes.map(option => ({
          value: option.value,
          expiry: option.expiry
        }));
        updateAndSaveSettings({
          companyOfferCouponCodes: offerCouponCodesAsStrings
        });
    };

    const updateAndSaveSettings = async ({ companyOfferCouponCodes }) => {
        // Assuming your Firestore collection is named 'companies'
        const companiesRef = collection(db, 'Companies');
    
        const userDocRef = doc(companiesRef, user.uid); // assuming user.uid is the user's UID
    
        const privateRef = collection(userDocRef, 'Private');
        const privateDocRef = doc(privateRef, 'Credentials');
        
        try {
            const userDocSnapshot = await getDoc(userDocRef);
    
            // Update the Firestore document with the new settings
            await updateDoc(userDocRef, {
              companyOfferCouponCodes: companyOfferCouponCodes
            });
        } catch (error) {
            console.error('Error updating Firestore document:', error);
        }
    };


    
    const updateFirebaseAffiliateOfferCode = async ( affiliate ) => {
        const companiesRef = collection(db, 'Companies');
        const userDocRef = doc(companiesRef, user.uid);

        const affiliateRef = doc(collection(userDocRef, 'Affiliates'), affiliate.affiliateEmail);

        try {
            await updateDoc(affiliateRef, {
                affiliateOfferCode: affiliate.localAffiliateOfferCode.value
            });

        } catch (error) {
            console.error("Error updating affiliate:", error);
        }
    }

    const findAffiliateDeepLinkCodeInFirebaseFromUsername = (affiliateName, firebaseAffiliates) => {
        const foundAffiliate = firebaseAffiliates.find(affiliate => affiliate.affiliatename === affiliateName);
        if (foundAffiliate) {
            return foundAffiliate.deeplinkurl;
        } else {
            return null;
        }
    };

    const findAffiliateShortCodeInFirebaseFromUsername = (affiliateCode, firebaseAffiliates) => {
        const foundAffiliate = firebaseAffiliates.find(affiliate => affiliate.affiliatename === affiliateCode);
        if (foundAffiliate) {
            return foundAffiliate.affiliateShortCode;
        } else {
            return null;
        }
    };

    const findAffiliateEmailInFirebaseFromUsername = (affiliateCode, firebaseAffiliates) => {
        const foundAffiliate = firebaseAffiliates.find(affiliate => affiliate.affiliatename === affiliateCode);
        if (foundAffiliate) {
            return foundAffiliate.email;
        } else {
            return null;
        }
    };

    const findAffiliateTransactionsAffiliateHasBeenPaidForInFirebaseFromUsername = (affiliateCode, firebaseAffiliates) => {
        const foundAffiliate = firebaseAffiliates.find(affiliate => affiliate.affiliatename === affiliateCode);

        if (foundAffiliate) {
            return foundAffiliate.transactionsAffiliateHasBeenPaidFor;
        } else {
            return null;
        }
    };

    const setAffiliateDetailsRetrievedFromFirebaseFromUsername = (affiliateCode, firebaseAffiliates) => {
        const foundAffiliate = firebaseAffiliates.find(affiliate => affiliate.affiliatename === affiliateCode);
        if (foundAffiliate) {
            setAffiliateEmail(foundAffiliate.email);
            setAffiliateName(foundAffiliate.affiliatename);
            setAffiliateShortCode(foundAffiliate.affiliateShortCode);
            setDeepLinkUrl(foundAffiliate.deeplinkurl);
            setAffiliateOfferCode(foundAffiliate.affiliateOfferCode);
            setTransactionsAffiliateHasBeenPaidFor(foundAffiliate.transactionsAffiliateHasBeenPaidFor || []);
            setTransactionsAffiliateHasNotBeenPaidFor(foundAffiliate.transactionsAffiliateHasNotBeenPaidFor || []);
            setTotalAffiliateHasBeenPaid(foundAffiliate.totalAffiliateHasBeenPaid || 0);
            setTotalAffiliateHasNotBeenPaid(foundAffiliate.totalAffiliateHasNotBeenPaid || 0);
        }
    };

    const fetchFirestoreAffiliates = async (uid) => {
        if (!uid) {
          console.error("User UID is not provided");
          return [];
        }

        const companiesRef = collection(db, 'Companies');
        const userDocRef = doc(companiesRef, uid);
    
        const affiliateCollection = collection(userDocRef, 'Affiliates');
        const affiliateCollectionSnapshot = await getDocs(affiliateCollection);
    
        let groupedDataFirebase = [];
        
        affiliateCollectionSnapshot.forEach((doc) => {
            let affiliateEntry = {};
            affiliateEntry.email = doc.data().email
            affiliateEntry.affiliatename = doc.data().affiliatename
            affiliateEntry.affiliateShortCode = doc.data().affiliateShortCode
            affiliateEntry.deeplinkurl = doc.data().deeplinkurl
            affiliateEntry.affiliateOfferCode = doc.data().affiliateOfferCode
            affiliateEntry.transactionsAffiliateHasBeenPaidFor = doc.data().transactionsAffiliateHasBeenPaidFor
            affiliateEntry.transactionsAffiliateHasNotBeenPaidFor = doc.data().transactionsAffiliateHasNotBeenPaidFor
            groupedDataFirebase.push(affiliateEntry);
        });
    
        return groupedDataFirebase
    };

    const formatDate = (date) => {
        let month = (date.getMonth() + 1).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');
        let year = date.getFullYear();
        return `${year}-${month}-${day}`;
    }

    const refactorIapticCustomersToMatchFirebaseAffiliates = (iapticCustomers, firebaseAffiliates) => {
        if (!Array.isArray(iapticCustomers)) {
            // console.error("iapticCustomers is not a valid array, possibly null or undefined.");
            return [];
        }
    
        return iapticCustomers.map((iapticCus) => {
            let affiliateMatched = false;
    
            firebaseAffiliates.forEach((firebaseAffiliate) => {
                if (firebaseAffiliate.deeplinkurl === iapticCus.applicationUsername) {
                    iapticCus.applicationUsername = firebaseAffiliate.affiliatename;
                    affiliateMatched = true;
                }
            });
    
            return affiliateMatched ? iapticCus : null;
        }).filter(Boolean);
    };

    const searchAnAffiliatesEvents = async () => {
        setSearchExecuted(true);
        setEventsTableData([]); // Reset table data
        setError(null);
    
        if (!affiliateCode) {
            setError("Affiliate code cannot be empty. Please enter a valid affiliate code.");
            return;
        }
    
        try {
            // Step 1: Get Firebase affiliates and find the storedDeepLink for the affiliate code
            const firebaseAffiliates = await fetchFirestoreAffiliates(user.uid);
            const affiliateDeepLinkCode = findAffiliateDeepLinkCodeInFirebaseFromUsername(affiliateCode, firebaseAffiliates);
            
            if (!affiliateDeepLinkCode) {
                throw new Error("Affiliate deep link code not found in Firebase.");
            }
    
            let url = `${apiUrl}/userEvents/${user.uid}?storedDeepLink=${encodeURIComponent(affiliateDeepLinkCode)}`;

            // Add query parameters if startDate and endDate are provided
            const queryParams = [];
            if (startDate) queryParams.push(`startDate=${encodeURIComponent(startDate)}`);
            if (endDate) queryParams.push(`endDate=${encodeURIComponent(endDate)}`);

            if (queryParams.length > 0) {
                url += `?${queryParams.join('&')}`;
            }

            // Step 2: Fetch events using storedDeepLink
            const response = await fetch(url);
    
            if (!response.ok) {
                throw new Error("Failed to fetch events data.");
            }
    
            const eventsData = await response.json();
            setEventsTableData(eventsData);
    
        } catch (error) {
            console.error("Error fetching events:", error);
            setError("Failed to fetch events. Ensure affiliate details and dates are correct.");
        }
    };

    function removeTransactionsBeforeAffiliateExisted(formattedFirebaseAffiliateCreationDate, transactionData) {
        return transactionData.map((purchase) => {
            let transactions = purchase.transactions.filter((transaction) => {
                return transaction.purchaseDate >= formattedFirebaseAffiliateCreationDate;
            });
            return {
                applicationUsername: purchase.applicationUsername,
                transactions: transactions
            };
        });
    }

    const searchAnAffiliate = async (setDataMissingError = false) => {
        setSearchExecuted(true);
        setTableData([]);
        setError(null);

        if (affiliateCode === '' && !setDataMissingError) {
            setError("Affiliate code cannot be empty. Please enter a valid affiliate code.");
            return;
        } else if ((startDate === '' || !startDate) && endDate === '') {
            if (selectedVerificationMethod === 'RevenueCat') {
                try {
                    const firebaseAffiliates = await fetchFirestoreAffiliates(user.uid);
                    const foundEmail = findAffiliateEmailInFirebaseFromUsername(affiliateCode, firebaseAffiliates);
                    const shortCode = findAffiliateShortCodeInFirebaseFromUsername(affiliateCode, firebaseAffiliates);
                    const deepLink = findAffiliateDeepLinkCodeInFirebaseFromUsername(affiliateCode, firebaseAffiliates);
                    const offerCodes = firebaseAffiliates.find(affiliate => affiliate.affiliatename === affiliateCode).affiliateOfferCode;

                    setAffiliateEmail(foundEmail);
                    setAffiliateName(affiliateCode);
                    setAffiliateShortCode(shortCode);
                    setDeepLinkUrl(deepLink);
                    setAffiliateOfferCode(offerCodes);

                    fetch(`${apiUrl}/get-revenuecat-transactions-from-firebase?companyId=${companyId}&affiliateEmail=${foundEmail}`)
                        .then(response => {
                            if (!response.ok) {
                                throw new Error(`Error fetching transactions: ${response.status}`);
                            }
                            return response.json();
                        }).then(response => {
                            if (!response) {
                                throw new Error("Transactions data is undefined. Please check your settings.");
                            }

                            if (response.success) {
                                setTableData(response.transactions);
                            }
                        })
                } catch (error) {
                    console.error("Error fetching data:", error);
                }
            } else {
                fetch(`${apiUrl}/returnAllCustomersApplicationUsernames?appName=${iapticAppName}&secretKey=${iapticSecretKey}`)
                    .then(response => {
                        if (!response.ok) {
                            throw new Error(`Error fetching customers: ${response.status}`);
                        }
                        return response.json();
                    })
                    .then(async customers => {
                        if (!customers) {
                            throw new Error("Customers data is undefined. Please check your settings.");
                        }
                        // Find the affiliate within Firebase...
                        try {
                            const firebaseAffiliates = await fetchFirestoreAffiliates(user.uid);

                            let affiliateDeepLinkCode = findAffiliateDeepLinkCodeInFirebaseFromUsername(affiliateCode, firebaseAffiliates);
                            let affiliateShortCode = findAffiliateShortCodeInFirebaseFromUsername(affiliateCode, firebaseAffiliates);

                            setAffiliateDetailsRetrievedFromFirebaseFromUsername(affiliateCode, firebaseAffiliates);

                            // Find the affiliate within the list of customers from the receipt verification company
                            if (!affiliateDeepLinkCode) {
                                throw new Error("Affiliate deep link code not found in Firebase. Please check the affiliate code.");
                            }

                            const filteredCustomers = customers.filter(customer => 
                                customer.startsWith(affiliateDeepLinkCode) ||
                                customer.startsWith(affiliateShortCode)
                            );

                            // Map each customer to a promise that fetches their purchases
                            const promises = filteredCustomers.map(customer => {
                                const encodedCustomer = encodeURIComponent(customer);
                                
                                // The customer may be a url, like https://wvcxo.app.link/agwijEfw1Ib which needs escaping, how?
                                return fetch(`${apiUrl}/returnCustomerPurchases/${encodedCustomer}?appName=${iapticAppName}&secretKey=${iapticSecretKey}`)
                                    .then(response => response.json());
                            });

                            // Wait for all promises to resolve
                            return Promise.all(promises);
                        } catch (error) {
                            console.error("Error fetching data:", error);
                        }
                    })
                    .then(async (purchases) => {
                        // Pass the Firebase Affiliate's Creation Date ...
                        let firebaseAffiliateCreationDate = user.metadata.creationTime;
                        let formattedFirebaseAffiliateCreationDate = new Date(firebaseAffiliateCreationDate).toISOString();

                        // Transactions purchase date is in this format. I want firebaseAffiliateCreationDAte in this too - "2023-12-07T20:52:45.000Z"
                        let purchasesWithTransactionsBeforeAffiliateExistedRemoved = removeTransactionsBeforeAffiliateExisted(formattedFirebaseAffiliateCreationDate, purchases);

                        try {
                            const firebaseAffiliates = await fetchFirestoreAffiliates(user.uid);
                            refactorIapticCustomersToMatchFirebaseAffiliates(purchasesWithTransactionsBeforeAffiliateExistedRemoved, firebaseAffiliates);
                    
                            // Flatten the purchases array to process each transaction
                            const flattenedPurchases = purchasesWithTransactionsBeforeAffiliateExistedRemoved.flatMap((purchase) => 
                                purchase.transactions.map((transaction) => ({
                                    ...transaction,
                                    applicationUsername: purchase.applicationUsername, // Preserve applicationUsername if needed
                                }))
                            );
                            
                            const affiliateData = firebaseAffiliates.find(
                                (affiliate) => affiliate.affiliatename === affiliateCode
                            );
                    
                            if (!affiliateData) {
                                console.error("Affiliate not found in Firebase");
                                return;
                            }

                            const paidTransactions = affiliateData.transactionsAffiliateHasBeenPaidFor || [];
                            const unpaidTransactions = affiliateData.transactionsAffiliateHasNotBeenPaidFor || [];
                            
                            for (const purchase of flattenedPurchases) {
                                const transactionId = purchase.transactionId;
                                // Check if the transaction is in paid or unpaid arrays
                                if (
                                    !paidTransactions.includes(transactionId) &&
                                    !unpaidTransactions.includes(transactionId) &&
                                    !newUnpaidTransactions.includes(transactionId)
                                ) {
                                    // Add the new transaction to unpaid transactions
                                    setNewUnpaidTransactions(prevState => [...prevState, transactionId]);
                                }
                            }

                            // if (newUnpaidTransactions.length > 0) {
                            //     console.log("prevState: ", transactionsAffiliateHasNotBeenPaidFor);
                            //     console.log("New unpaid transactions found:", newUnpaidTransactions);
                            //     // Update local state
                            //     setTransactionsAffiliateHasNotBeenPaidFor((prevState) => [
                            //         ...prevState,
                            //         ...newUnpaidTransactions,
                            //     ]);

                            //     // TODO: this should be done in a use effect right?
                            //     const foundEmail = findAffiliateEmailInFirebaseFromUsername(affiliateCode, firebaseAffiliates);
                            //     // Update Firebase
                            //     const companiesRef = collection(db, 'Companies');
                            //     const userDocRef = doc(companiesRef, user.uid);
                            //     const affiliateRef = doc(collection(userDocRef, 'Affiliates'), foundEmail);
                    
                            //     await updateDoc(affiliateRef, {
                            //         transactionsAffiliateHasNotBeenPaidFor: [
                            //             ...transactionsAffiliateHasNotBeenPaidFor,
                            //             ...newUnpaidTransactions,
                            //         ],
                            //     });
                    
                            // }
                            // TODO: this should be done in a use effect right?
                            setTableData(purchasesWithTransactionsBeforeAffiliateExistedRemoved.flat());
                        } catch (error) {
                            console.error('Error processing purchases:', error);
                        }
                    })
                    .catch(error => {
                        console.error("Error fetching data:", error);

                        if (setDataMissingError) {
                            setError("Please enter your Iaptic App Name and Iaptic Secret Key in the Insert Affiliate's Settings to see an affiliates sales and settings.");
                        } else {
                            setError("Failed to fetch customers data. This often happens if your Iaptic credentials are incorrect.");
                        }
                    });
            }
        } else {
            // fetch(`${apiUrl}/returnAllCustomersApplicationUsernames?appName=${iapticAppName}&secretKey=${iapticSecretKey}`)
            //     .then(response => response.json())
            //     .then(async customers => {
            //         // Find the affiliate within Firebase...
            //         try {
            //             const firebaseAffiliates = await fetchFirestoreAffiliates(user.uid);

            //             let affiliateDeepLinkCode = findAffiliateDeepLinkCodeInFirebaseFromUsername(affiliateCode, firebaseAffiliates);

            //             setAffiliateDetailsRetrievedFromFirebaseFromUsername(affiliateCode, firebaseAffiliates);

            //             if (!affiliateDeepLinkCode) {
            //                 throw new Error("Affiliate deep link code not found in Firebase. Please check the affiliate code.");
            //             }

            //             // Find the affiliate within the list of customers from the receipt verification company
            //             const filteredCustomers = customers.filter(customer => customer.startsWith(affiliateDeepLinkCode));

            //             if (filteredCustomers.length === 0) {
            //                 setError("No matching customers found for the provided affiliate code.");
            //                 return;
            //             }

            //             let defaultStartDate = startDate || '2020-01-01'; // Use '01/01/2020' if startDate is null
            //             let tomorrow = new Date();
            //             tomorrow.setDate(tomorrow.getDate() + 1);
            //             let defaultEndDate = endDate || formatDate(tomorrow); // Use tomorrow's date if endDate is null

            //             const promises = filteredCustomers.map(customer => {
            //                 const encodedCustomer = encodeURIComponent(customer);
    
            //                 return fetch(`${apiUrl}/returnCustomerPurchasesBetweenDates/${encodedCustomer}/${defaultStartDate}/${defaultEndDate}/${iapticAppName}/${iapticSecretKey}`)
            //                     .then(response => {
            //                         if (!response.ok) {
            //                             throw new Error(`Error fetching purchases for customer ${encodedCustomer}: ${response.status}`);
            //                         }
            //                         return response.json();
            //                     })
            //                     .catch(error => {
            //                         console.error(`Error fetching data for ${customer}:`, error);
            //                         return null; // Handle error and continue processing other requests
            //                     });
            //             });

            //             // Wait for all promises to resolve
            //             return Promise.all(promises);
            //         } catch (error) {
            //             console.error("Error fetching data:", error);
            //         }

            //     })
            //     .then(async purchases => {
            //         try {
            //             const firebaseAffiliates = await fetchFirestoreAffiliates(user.uid);

            //             refactorIapticCustomersToMatchFirebaseAffiliates(purchases, firebaseAffiliates);

            //             // You may want to process the 'purchases' array to get the desired format for 'setTableData'
            //             // For example, you might flatten the array if each customer's purchases are in a separate array
            //             setTableData(purchases.flat());
            //         } catch (error) {
            //             console.error("Error fetching data:", error);
            //         }

            //     })
            //     .catch(error => {
            //         console.error("Error fetching data:", error);
            //         setError("Failed to fetch customers data. Tip: This often happens if your Iaptic credentials are incorrect.");
            //     });
        }
    };

    return (
        <Container>
            <div>
                <div className="pb-8 mx-aut" style={{ minHeight: '65vh' }}>
                    <BillingAlertModal />
                    <div className="pb-2">
                        <h1 className="font-display text-2xl tracking-tight text-slate-900 sm:text-3xl">
                            Search An Affiliate
                        </h1>
                        <p className="mt-2 text-lg tracking-tight text-slate-700">
                            Search for an affiliate by their identifier to find their sales
                        </p>
                    </div>
                    {error && (
                        <div className="mb-4 p-4 text-red-700 bg-red-100 border border-red-400 rounded">
                            {error}
                        </div>
                    )}
                    <div className="flex flex-wrap items-center space-y-4 md:space-y-0 md:space-x-4">
                        <div className="flex-grow">
                            <label htmlFor="affiliateCode" className="hidden">
                                Affiliate's Code:
                            </label>
                            <input
                                type="text"
                                value={affiliateCode}
                                onChange={(e) => setAffiliateCode(e.target.value)}
                                placeholder="Enter Affiliate Code"
                                className="block w-full appearance-none rounded-md border border-gray-300 bg-gray-50 px-3 py-2 text-gray-900 placeholder-gray-400 focus:outline-none focus:ring focus:border-blue-500 sm:text-sm"
                            />
                        </div>
                    </div>

                    {searchExecuted && tableData.length === 0 && (
                        <div>
                            <p className="mt-4">No sales data available for the selected criteria.</p>
                        </div>
                    )}

                    {searchExecuted && affiliateEmail !== "" && (
                        <AffiliateDetails
                            affiliateEmail={affiliateEmail}
                            affiliateName={affiliateName}
                            affiliateShortCode={affiliateShortCode}
                            deepLinkUrl={deepLinkUrl}
                            localAffiliateOfferCode={localAffiliateOfferCode}
                            handleChange={handleChange}
                            handleCreate={handleCreate}
                            localCompanyOfferCouponCodes={localCompanyOfferCouponCodes}
                            selectedCode={selectedCode}
                            handleSubmit={handleSubmit}
                        />
                    )}

                    {/* Toggle buttons for switching views */}
                    <div className="mt-16 flex space-x-4">
                        <button
                            onClick={() => setView('Transactions')}
                            className={`px-4 py-2 ${view === 'Transactions' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'} rounded`}
                        >
                            Transactions
                        </button>
                        <button
                            onClick={() => setView('Events')}
                            className={`px-4 py-2 ${view === 'Events' ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'} rounded`}
                        >
                            Events
                        </button>
                    </div>

                    {searchExecuted && view === 'Transactions' && (
                        tableData.length > 0 ? (
                            selectedVerificationMethod === 'RevenueCat' ? (
                                <AffiliateRevenueCatSalesTransactions
                                    tableData={tableData}
                                    setTableData={setTableData}
                                    startDate={startDate}
                                    endDate={endDate}
                                    setStartDate={setStartDate}
                                    setEndDate={setEndDate}
                                    companyAffiliatePaymentPercentage={companyAffiliatePaymentPercentage}
                                    transactionsAffiliateHasBeenPaidFor={transactionsAffiliateHasBeenPaidFor}
                                    setTransactionsAffiliateHasBeenPaidFor={setTransactionsAffiliateHasBeenPaidFor}
                                    transactionsAffiliateHasNotBeenPaidFor={transactionsAffiliateHasNotBeenPaidFor}
                                    setTransactionsAffiliateHasNotBeenPaidFor={setTransactionsAffiliateHasNotBeenPaidFor}
                                    affiliateEmail={affiliateEmail}
                                    user={user}
                                    totalAffiliateHasBeenPaid={totalAffiliateHasBeenPaid}
                                    setTotalAffiliateHasBeenPaid={setTotalAffiliateHasBeenPaid}
                                    totalAffiliateHasNotBeenPaid={totalAffiliateHasNotBeenPaid}
                                    setTotalAffiliateHasNotBeenPaid={setTotalAffiliateHasNotBeenPaid}
                                />
                            ) : (
                                <AffiliateSalesTransactions
                                    tableData={tableData}
                                    startDate={startDate}
                                    endDate={endDate}
                                    setStartDate={setStartDate}
                                    setEndDate={setEndDate}
                                    companyAffiliatePaymentPercentage={companyAffiliatePaymentPercentage}
                                    transactionsAffiliateHasBeenPaidFor={transactionsAffiliateHasBeenPaidFor}
                                    setTransactionsAffiliateHasBeenPaidFor={setTransactionsAffiliateHasBeenPaidFor}
                                    transactionsAffiliateHasNotBeenPaidFor={transactionsAffiliateHasNotBeenPaidFor}
                                    setTransactionsAffiliateHasNotBeenPaidFor={setTransactionsAffiliateHasNotBeenPaidFor}
                                    affiliateEmail={affiliateEmail}
                                    user={user}
                                    totalAffiliateHasBeenPaid={totalAffiliateHasBeenPaid}
                                    setTotalAffiliateHasBeenPaid={setTotalAffiliateHasBeenPaid}
                                    totalAffiliateHasNotBeenPaid={totalAffiliateHasNotBeenPaid}
                                    setTotalAffiliateHasNotBeenPaid={setTotalAffiliateHasNotBeenPaid}
                                />
                            )
                        ) : (
                            <h2 className="mt-6 font-display text-xl tracking-tight text-slate-900 sm:text-2xl">No transactions found.</h2>
                        )
                    )}

                    {searchExecuted && view === 'Events' && (
                        eventsTableData.length > 0 ? (
                            <AffiliateEvents
                                tableData={eventsTableData}
                                setTableData={setEventsTableData}
                                startDate={startDate}
                                endDate={endDate}
                                setStartDate={setStartDate}
                                setEndDate={setEndDate}
                                companyAffiliatePaymentPercentage={companyAffiliatePaymentPercentage}
                                transactionsAffiliateHasBeenPaidFor={transactionsAffiliateHasBeenPaidFor}
                                setTransactionsAffiliateHasBeenPaidFor={setTransactionsAffiliateHasBeenPaidFor}
                                transactionsAffiliateHasNotBeenPaidFor={transactionsAffiliateHasNotBeenPaidFor}
                                setTransactionsAffiliateHasNotBeenPaidFor={setTransactionsAffiliateHasNotBeenPaidFor}
                                affiliateEmail={affiliateEmail}
                                user={user}
                                totalAffiliateHasBeenPaid={totalAffiliateHasBeenPaid}
                                setTotalAffiliateHasBeenPaid={setTotalAffiliateHasBeenPaid}
                                totalAffiliateHasNotBeenPaid={totalAffiliateHasNotBeenPaid}
                                setTotalAffiliateHasNotBeenPaid={setTotalAffiliateHasNotBeenPaid}
                            />
                        ) : (
                            <h2 className="mt-6 font-display text-l tracking-tight text-slate-900 sm:text-xl">No events have been found.</h2>
                        )
                    )}

                </div>
            </div>
        </Container>
    );
};
export default AffiliateSearch;
