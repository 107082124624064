import { Fragment, useState, useEffect } from 'react'
import { OnboardingModal } from '../components/OnboardingModal';


import { useAuth } from '../contexts/AuthContext';
import  Avatar  from './Avatar';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { signOut } from 'firebase/auth';
import { Logo } from './Logo'
import {
  Dialog,
  Menu,
  Transition,
} from '@headlessui/react'
import {
  Bars3Icon,
  CreditCardIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  PlusCircleIcon,
  BanknotesIcon,
  QuestionMarkCircleIcon
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'

import { LightBulbIcon } from '@heroicons/react/24/outline';


const adminNavigation = [
  { name: 'Dashboard', href: '/', icon: HomeIcon, current: true },
];

const adminAffiliateNavigation = [
  { name: 'Create Affiliate', href: '/create-an-affiliate', icon: PlusCircleIcon, current: false },
  { name: 'Affiliates', href: '/affiliates', icon: UsersIcon, current: false },
  { name: 'Search Affiliate', href: '/affiliate-search', icon: MagnifyingGlassIcon, current: false },
]

const affiliateNavigation = [
  { name: 'Dashboard', href: '/', icon: HomeIcon, current: true },
];

const affiliateAffiliateNavigation = [
  { name: 'Track My Sales', href: '/affiliate-self-search', icon: BanknotesIcon, current: false },
]

const userNavigation = [
  { name: 'Your profile', href: '/profile' },
  { name: 'Sign out', href: '/sign-in' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const handleSignOut = async () => {
  const auth = getAuth();

  try {
    await signOut(auth); // Replace 'auth' with your Firebase authentication instance
    // After successful sign out, you can redirect the user to another page or perform any other action
  } catch (error) {
    console.error('Error signing out:', error.message);
  }
};

export default function DashboardLayout({ children })  {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { user } = useAuth();
  const [isAffiliate, setIsAffiliate] = useState(null);
  const location = useLocation();
  const auth = getAuth();
  const [showOnboarding, setShowOnboarding] = useState(false);

  useEffect(() => {
    if (user) {
      const auth = getAuth();
      auth.currentUser.getIdTokenResult()
        .then((idTokenResult) => {
          setIsAffiliate(idTokenResult.claims.affiliate || false);
        })
        .catch((error) => {
          console.error("Error fetching user claims", error);
          setIsAffiliate(false);
        });
    } else {
      console.error("no user signed in");
      setIsAffiliate(false);
    }
  }, [user]);

  const handleCloseModal = () => {
    setShowOnboarding(false);
  };

  let navigation = isAffiliate ? affiliateNavigation : adminNavigation;

  let affiliateNavigationBar = isAffiliate ? affiliateAffiliateNavigation : adminAffiliateNavigation;

  navigation = navigation.map((item) => ({
    ...item,
    current: item.href === location.pathname,
  }));
  
  affiliateNavigationBar = affiliateNavigationBar.map((item) => ({
    ...item,
    current: item.href === location.pathname,
  }));

  return (
    <>
      <div>
        {showOnboarding && <OnboardingModal type="admin" onClose={handleCloseModal} />}

        <Transition show={sidebarOpen}>
          <Dialog className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1 h-full">
                  <Transition.Child
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-purple-600 px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                        <Logo className="h-10 w-auto" />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                
                                <Link to={item.href}
                                  className={classNames(
                                    item.current
                                      ? 'bg-purple-700 text-white'
                                      : 'text-slate-200 hover:text-white hover:bg-purple-700',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold',
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      item.current ? 'text-white' : 'text-slate-200 group-hover:text-white',
                                      'h-6 w-6 shrink-0'
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {affiliateNavigationBar.map((item) => (
                              <li key={item.name}>
                                
                                <Link to={item.href}
                                  className={classNames(
                                    item.current
                                      ? 'bg-purple-700 text-white'
                                      : 'text-slate-200 hover:text-white hover:bg-purple-700',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      item.current ? 'text-white' : 'text-slate-200 group-hover:text-white',
                                      'h-6 w-6 shrink-0'
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li className="mt-auto">
                          {isAffiliate ? (
                            <>
                                <a
                                  href="company-contact"
                                  className={classNames(
                                      'group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-slate-200 hover:bg-purple-700 hover:text-white',
                                      location.pathname === '/company-contact' ? 'bg-purple-700 text-white' : ''
                                  )}
                                >
                                  <Cog6ToothIcon
                                      className="h-6 w-6 shrink-0 text-slate-200 group-hover:text-white"
                                      aria-hidden="true"
                                  />
                                  Company Contact
                                </a>
                                <a
                                    href="affiliate-settings"
                                    className={classNames(
                                        'group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-slate-200 hover:bg-purple-700 hover:text-white',
                                        location.pathname === '/affiliate-settings' ? 'bg-purple-700 text-white' : ''
                                    )}
                                >
                                    <Cog6ToothIcon
                                        className="h-6 w-6 shrink-0 text-slate-200 group-hover:text-white"
                                        aria-hidden="true"
                                    />
                                    Settings
                                </a>
                            </>
                        ) : (
                          <>
                            <div
                              onClick={() => setShowOnboarding(true)}
                              className={classNames(
                                  'group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-slate-200 hover:bg-purple-700 hover:text-white',
                              )}
                            >
                              <LightBulbIcon
                                  className="h-6 w-6 shrink-0 text-slate-200 group-hover:text-white"
                                  aria-hidden="true"
                              />
                              Onboarding Guide
                            </div>
                            <a
                              href="billing"
                              className={classNames(
                                  'group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-slate-200 hover:bg-purple-700 hover:text-white',
                                  location.pathname === '/billing' ? 'bg-purple-700 text-white' : ''
                              )}
                            >
                              <CreditCardIcon
                                  className="h-6 w-6 shrink-0 text-slate-200 group-hover:text-white"
                                  aria-hidden="true"
                              />
                              Billing
                            </a>
                            <a
                              href="help"
                              className={classNames(
                                'group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-slate-200 hover:bg-purple-700 hover:text-white',
                                location.pathname === '/help' ? 'bg-purple-700 text-white' : ''
                              )}
                            >
                              <Cog6ToothIcon
                                className="h-6 w-6 shrink-0 text-slate-200 group-hover:text-white"
                                aria-hidden="true"
                              />
                              Settings
                            </a>
                          </>
                            
                        )}
                        </li>
                        <li className="mt-auto">
                            <a href="help"
                              className={classNames(
                                'group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-slate-200 hover:bg-purple-700 hover:text-white',
                                location.pathname === '/help' ? 'bg-purple-700 text-white' : ''
                              )}
                            >
                            <QuestionMarkCircleIcon
                              className="h-6 w-6 shrink-0 text-slate-200 group-hover:text-white"
                              aria-hidden="true"
                            />
                            Help
                          </a>
                        </li>
                        
                       
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-purple-400 px-6 pb-4">
            <div className="flex h-16 shrink-0 items-center">
            <Logo className="h-10 w-auto" />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <a
                          href={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-purple-600 text-white'
                              : 'text-slate-200 hover:text-white hover:bg-purple-700',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current ? 'text-white' : 'text-slate-200 group-hover:text-white',
                              'h-6 w-6 shrink-0'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                <li>
                    <div className="text-xs font-semibold leading-6 text-slate-200">Affiliates</div>
                    <ul role="list" className="-mx-2 space-y-1">
                        {affiliateNavigationBar.map((item) => (
                        <li key={item.name}>
                            <a
                            href={item.href}
                            className={classNames(
                                item.current
                                ? 'bg-purple-600 text-white'
                                : 'text-slate-200 hover:text-white hover:bg-purple-700',
                                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                            )}
                            >
                            <item.icon
                                className={classNames(
                                item.current ? 'text-white' : 'text-slate-200 group-hover:text-white',
                                'h-6 w-6 shrink-0'
                                )}
                                aria-hidden="true"
                            />
                            {item.name}
                            </a>
                        </li>
                        ))}
                    </ul>
                </li>
                <li className="mt-auto">
                    {isAffiliate ? (
                        <>
                          <a
                            href="contact-company"
                            className={classNames(
                                'group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-slate-200 hover:bg-purple-700 hover:text-white',
                                location.pathname === '/contact-company' ? 'bg-purple-700 text-white' : ''
                            )}
                          >
                            <Cog6ToothIcon
                                className="h-6 w-6 shrink-0 text-slate-200 group-hover:text-white"
                                aria-hidden="true"
                            />
                            Contact Company
                          </a>
                          <a
                            href="affiliate-settings"
                            className={classNames(
                              'group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-slate-200 hover:bg-purple-700 hover:text-white',
                              location.pathname === '/affiliate-settings' ? 'bg-purple-700 text-white' : ''
                            )}
                          >
                            <Cog6ToothIcon
                              className="h-6 w-6 shrink-0 text-slate-200 group-hover:text-white"
                              aria-hidden="true"
                              />
                              Settings
                          </a>
                        </>
                    ) : (
                      <>
                        <div
                          onClick={() => setShowOnboarding(true)}
                          className={classNames(
                              'group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-slate-200 hover:bg-purple-700 hover:text-white',
                          )}
                        >
                          <LightBulbIcon
                              className="h-6 w-6 shrink-0 text-slate-200 group-hover:text-white"
                              aria-hidden="true"
                          />
                          Onboarding Guide
                        </div>
                        <a
                          href="billing"
                          className={classNames(
                              'group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-slate-200 hover:bg-purple-700 hover:text-white',
                              location.pathname === '/billing' ? 'bg-purple-700 text-white' : ''
                          )}
                        >
                          <CreditCardIcon
                              className="h-6 w-6 shrink-0 text-slate-200 group-hover:text-white"
                              aria-hidden="true"
                          />
                          Billing
                        </a>
                        <a
                          href="settings"
                          className={classNames(
                              'group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-slate-200 hover:bg-purple-700 hover:text-white',
                              location.pathname === '/settings' ? 'bg-purple-700 text-white' : ''
                          )}
                        >
                          <Cog6ToothIcon
                              className="h-6 w-6 shrink-0 text-slate-200 group-hover:text-white"
                              aria-hidden="true"
                          />
                          Settings
                        </a>
                      </>
                      
                    )}
                    <a href="help"
                      className={classNames(
                        'group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-slate-200 hover:bg-purple-700 hover:text-white',
                        location.pathname === '/help' ? 'bg-purple-700 text-white' : ''
                      )}
                    >
                      <QuestionMarkCircleIcon
                        className="h-6 w-6 shrink-0 text-slate-200 group-hover:text-white"
                        aria-hidden="true"
                      />
                      Help
                    </a>
                  </li>
                    
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-72">
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div className="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6 justify-end	">
              <div className="flex items-center gap-x-4 lg:gap-x-6">
                

                {/* Separator */}
                <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" aria-hidden="true" />

                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <Menu.Button className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Open user menu</span>
                    {user ? user.displayName ? <Avatar displayName={user.displayName} /> : <Avatar displayName={null} /> : <Avatar displayName={null} />}
                    
                    <span className="hidden lg:flex lg:items-center">
                      <span className="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
                        {user ? user.displayName ? user.displayName : 'My Account' : 'My Account'}
                      </span>
                      <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </Menu.Button>
                  <Transition
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ focus }) => (
                            item.name == 'Sign out' ? (
                              <button
                                onClick={handleSignOut}
                                className={classNames(
                                  focus ? 'bg-gray-50' : '',
                                  'block px-3 py-1 text-sm leading-6 text-gray-900'
                                )}
                              >
                                {item.name}
                              </button>
                            ) : (
                              <a
                                href={item.href}
                                className={classNames(
                                  focus ? 'bg-gray-50' : '',
                                  'block px-3 py-1 text-sm leading-6 text-gray-900'
                                )}
                              >
                                {item.name}
                              </a>
                            )
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main className="flex-1 py-10">
            <div className="px-4 sm:px-6 lg:px-8">
                {/* Render different content based on the page */}
                {children}
            </div>
            </main>
        </div>
      </div>
    </>
  )
}