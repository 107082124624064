

import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, doc } from 'firebase/firestore';
import { db } from '../firebase';
import FetchUserAndFirebaseDocsOnAuth from './fetchFirebaseDocsOnAuth';
import { ApiContext } from '../contexts/ApiContext';
import { getDocs } from 'firebase/firestore';

export const BillingAlertModal = (props) => {
    const [ showBillingAlert, setShowBillingAlert ] = React.useState(false);
    const navigate = useNavigate();
    const { priceId } = useContext(ApiContext);
    const user = FetchUserAndFirebaseDocsOnAuth();

    const getAffiliateCount = async () => {
        const companiesRef = collection(db, 'Companies');
        const userDocRef = doc(companiesRef, user.uid);
        const affiliateRef = collection(userDocRef, 'Affiliates');
        const affiliateSnapshot = await getDocs(affiliateRef);
        return affiliateSnapshot.size;
    };

    // Function to check affiliate count and set billing alert
    const checkAffiliateLimits = async () => {
        const count = await getAffiliateCount();
        console.log('Checking affiliate limits count:', count);
        console.log("priceId", priceId);
        const limits = {
            'price_1Ql9YXRuLSxnvF115scnT7Vu': 5, // Number of affiliates for the starter plan at 14.95 per month
            'price_1QRAvPRuLSxnvF11IfC1yMhk': 50, // Number of affiliates for the growth plan at 49.95 per month
            'price_1PPtWrRuLSxnvF11jm5646Zw': 50, // Number of affiliates for the early bird growth plan at 39.95 per month
        };

        if (priceId in limits && count > limits[priceId]) {
            setShowBillingAlert(true);
        }
    };


    useEffect(() => {
        if (user) {
            checkAffiliateLimits();
        }
        
    }, [priceId, user]);

    return (
        showBillingAlert && (
            <div className="bg-red-100 border border-red-400 text-red-700 rounded-lg p-4 max-w-lg w-full mb-10">
                <h3 className="font-bold text-lg">You have hit your limits within your billing plan.</h3>
                <p className="mt-2 text-sm">
                    We implement soft limits to foster your marketing growth based on trust. To continue enjoying uninterrupted service, please upgrade your plan or reach out to us.
                </p>
                <button
                    className="mt-4 bg-red-700 text-white px-4 py-2 rounded hover:bg-red-800"
                    onClick={() => navigate('/billing')}
                >
                    Go to Billing
                </button>
            </div>
        )
    );
};

export default BillingAlertModal;